import React from "react";
import { Link } from "gatsby";
import { Button } from "../../../components/Button/Button";
import { ContainerBanner, ContentBanner } from "./styledBanner";

export function Banner({ data }) {
  const { titleBanner, subTitleBanner, imageBanner, buttonText, buttonSlug, buttonLink } = data;
  
  return (
    <ContainerBanner>
      <ContentBanner>
        <h1>{titleBanner}</h1>
        <p>{subTitleBanner}</p>
        {buttonSlug ? (
          <Link to={`/${buttonSlug}`}>
            <Button type="button" label={buttonText} />
          </Link>
        ) : (
          <a href={buttonLink} target="blank">
            <Button type="button" label={buttonText} />
          </a>
        )}
      </ContentBanner>

      <img src={imageBanner?.url} alt={imageBanner?.fileName} loading="lazy" />
    </ContainerBanner>
  );
}
