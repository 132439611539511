import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Button } from "../../../components/Button/Button";
import { Input } from "../../../components/Input/Input";
import { Select } from "../../../components/Select/Select";
import { optionsSegmentoEmpresa } from "../../../constants/formOptions";

import {
  CapsuleField,
  FooterForm,
  FormularioWrapper,
  WrapperButton,
  WrapperInput,
} from "./styledFormHome";
import axios from "axios";
import { Loading } from "../../../components/Loading/Loading";
import { sendRequest } from "../../../services/sendRequest";

export function Formhome({ myRef }) {
  const [sendingEmail, setSendingEmail] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formId = "70e495cf-c8c0-4e05-9d65-04a8295be290";
  const portalId = "8759286";

  const [result, setResult] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `https://qsqd2pawhwgim2on6dsn2gfgv40fxkqj.lambda-url.us-east-1.on.aws`
        );
        if (result.status === 200) {
          setResult(result.data);
          return result;
        }
      } catch (error) {
        console.error(error);
        return error;
      }
    };
    return fetchData();
  }, []);

  const repeatIndex = result.findIndex(({ guid }) => {
    return guid === formId;
  });

  const labelFields = result[repeatIndex]?.formFieldGroups?.map(
    ({ fields }) => fields
  );

  const verifyLabel = labelFields === undefined ? [] : labelFields;

  const submitHubspotForm = async (data) => {
    const fields = Object.keys(data).map((key) => ({
      name: key,
      value: data[key],
    }));

    try {
      setSendingEmail(true);

      const response = await sendRequest.sendEmail(portalId, formId, {
        fields,
      });;

      const { status } = response;

      if (status === 200) {
        toast.success(
          "Solicitação registrada com sucesso! Em breve você receberá o contato de nossos especialistas.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

      setSendingEmail(false);
    } catch (error) {
      setSendingEmail(false);

      if (error) {
        toast.error("Erro ao enviar o formulário, tente novamente.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      return error;
    }
  };

  const onSubmit = handleSubmit((data) => {
    const hubspot_response = submitHubspotForm(data);
      return hubspot_response;
  });


  const flattenedArray = verifyLabel.filter((fields) => !fields[0]?.hidden).reduce((accumulator, currentArray) => {
    return accumulator.concat(currentArray);
  }, []);

  const getInputs = (labelFields) => {
    return labelFields?.map(({ name, label, placeholder, type, required }) => {
      if (type !== "enumeration") {
        return (
          <CapsuleField key={name}>
            <label htmlFor={name}>
              {label}
              {required && " *"}
            </label>
            <Input
              {...register(name, { required })}
              name={name}
              id={name}
              type="text"
              placeholder={placeholder}
            />
            {errors?.firstname?.type === "required" && <p>Campo obrigatório</p>}
          </CapsuleField>
        );
      }


      if (type === "enumeration" && name === 'segmento_da_empresa') {
        return (
          <CapsuleField key={name}>
            <label htmlFor={name}>
              {label}
              {required && " *"}
            </label>
            <Select
              id={name}
              {...register(name, {
                required,
              })}
            >
              {optionsSegmentoEmpresa.map(({ optionLabel, optionValue }) => (
                <option key={optionLabel} value={optionValue}>
                  {optionLabel}{" "}
                </option>
              ))}
            </Select>
            {errors?.segmento_da_empresa?.type === "required" && (
              <p>Campo obrigatório</p>
            )}
          </CapsuleField>
        );
      }
      return [];
    });
  };

  return (
    <FormularioWrapper onSubmit={onSubmit} ref={myRef}>
      <WrapperInput justify="space-between" maxWidth={806}>
        <Loading loading={labelFields === undefined} />
          {getInputs(flattenedArray)}
      </WrapperInput>

      <FooterForm>
        <input
          type="checkbox"
          {...register("acceptTerms", { required: true })}
          id="acceptTerms"
        />
        <label htmlFor="acceptTerms">
          {" "}
          Eu concordo em receber outras comunicações da Cinnecta.
        </label>
        {errors?.acceptTerms?.type === "required" && (
          <p>Aceite os termos de privacidade.</p>
        )}
        <span>
          Ao informar meus dados, concordo com a{" "}
          <a
            href="https://cinnecta.com/privacidade/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Política de Privacidade.
          </a>{" "}
          Você pode alterar suas permissões de comunicação a qualquer tempo.
        </span>
      </FooterForm>
      <WrapperButton>
        <Button
          type="submit"
          label="solicite uma demo"
          disabled={sendingEmail}
        />
        <Loading loading={sendingEmail} />
      </WrapperButton>
    </FormularioWrapper>
  );
}
