import * as React from "react";
import { graphql } from "gatsby";

import { Seo } from "../components/Seo";
import { Banner } from "../view/Home/banner";
import Layout from "../templates/MainTemplate";
import { useHome } from "../hooks/methods/home";
import { StampsView } from "../view/Home/stamps";
import { ProductsView } from "../view/Home/products";
import { ServicesView } from "../view/Home/serviços";
import { CompaniesView } from "../view/Home/companies";
import { DepositionsView } from "../view/Home/depositions";

function Home() {
  const { data, isSuccess } = useHome();
  const {
    banner,
    infoCard,
    formTitle,
    imageStamp,
    stampsTitle,
    productsTitle,
    stapmsCinnecta,
    leadingCompany,
    depositionsCard,
    productsCinnecta,
  } = isSuccess && data?.homepage;

  const dataStamps = [imageStamp, stapmsCinnecta];

  return (
    <>
      <Layout>
        <Banner data={isSuccess && banner} />

        <ServicesView data={infoCard} />
        <CompaniesView data={isSuccess && leadingCompany} />
        <DepositionsView data={depositionsCard} />
        <ProductsView
          data={productsCinnecta}
          formTitle={formTitle}
          productsTitle={productsTitle}
        />
        <StampsView data={isSuccess && dataStamps} title={stampsTitle} />
      </Layout>
    </>
  );
}

export const Head = ({
  data: {
    cmsHomepage: {
      seo: {
        title: seoTitle,
        description: seoDescription,
        keywords,
        image: { url },
      },
    },
  },
}) => {
  const keywordsList = keywords.toString();
  return (
    <Seo title={seoTitle}>
      <meta name="og:description" content={seoDescription} />
      <meta name="og:image" content={url} />
      <meta name="og:title" content={seoTitle} />
      <meta name="description" content={seoDescription} />
      <meta name="keywords" content={keywordsList} />
      <title>{seoTitle}</title>
    </Seo>
  );
};

export const query = graphql`
  query PageQuery {
    cmsHomepage {
      seo {
        title
        description
        keywords
        image {
          url
        }
      }
    }
  }
`;

export default Home;
